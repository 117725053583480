import React, { useState } from "react";
import { PaperAirplaneIcon } from "@heroicons/react/24/solid";
import { useForm } from "react-hook-form";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import FormAlert from "./FormAlert";
import TextField from "./TextField";
import Button from "./Button";
import LoadingIcon from "./LoadingIcon";
import contact from "./../util/contact";
import { trackEvent } from "../util/analytics";

function ContactSection2(props) {
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    // Show pending indicator
    setPending(true);

    contact
      .submit(data)
      .then(() => {
        // Clear form
        reset();
        // Show success alert message
        setFormAlert({
          type: "success",
          message: "Your message has been sent!",
        });
      })
      .catch((error) => {
        // Show error alert message
        setFormAlert({
          type: "error",
          message: error.message,
        });
      })
      .finally(() => {
        trackEvent("contactFormSubmit", {
          name: data.name,
          email: data.email,
          message: data.message,
        });
        // Hide pending indicator
        setPending(false);
      });
  };

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="relative overflow-hidden">
        <div className="absolute top-0 left-0 w-32 h-48 text-gray-200/60 transform -translate-x-16 translate-y-12 pattern-dots md:h-96 md:-translate-x-4" />
        <div className="absolute bottom-0 right-0 w-32 h-48 text-gray-200/60 transform translate-x-16 -translate-y-12 pattern-dots md:h-96 md:translate-x-6" />
        <div className="container relative px-4 mx-auto space-y-16 xl:max-w-7xl lg:px-8">
          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            strapline={props.strapline}
            className="text-center"
          />
          <div className="max-w-2xl mx-auto">
            {formAlert && (
              <div className="mb-6">
                <FormAlert type={formAlert.type} message={formAlert.message} />
              </div>
            )}

            <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                <div>
                  <TextField
                    type="text"
                    label="Name"
                    id="name"
                    placeholder=""
                    error={errors.name}
                    {...register("name", {
                      required: "Please enter your name",
                    })}
                  />
                </div>
                <div>
                  <TextField
                    type="email"
                    label="Email"
                    id="email"
                    placeholder=""
                    error={errors.email}
                    {...register("email", {
                      required: "Please enter your email",
                    })}
                  />
                </div>
              </div>
              <div>
                <TextField
                  type="textarea"
                  label="Message"
                  id="message"
                  placeholder=""
                  error={errors.message}
                  rows={6}
                  {...register("message", {
                    required: "Please enter a message",
                  })}
                />
              </div>
              <Button
                type="submit"
                size="lg"
                disabled={pending}
                isBlock={true}
                placement={"ContactSection-send"}
                startIcon={
                  !pending && (
                    <PaperAirplaneIcon className="inline-block w-5 h-5 opacity-50" />
                  )
                }
                className="w-48"
              >
                {!pending && <>Send message</>}

                {pending && <LoadingIcon className="w-6" />}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default ContactSection2;
