import {
  waveryIcon,
  waveryWorkflowZoom,
  // whisperScriptMainIcon,
} from "../assets";

export const products = [
  {
    title: "WhisperScript 2",
    description: "The Ultimate Desktop Transcription Software for your Mac",
    price: 9,
    image: waveryIcon,
    url: "/#whisperscript",
    iconBg: "blue",
    tag: "New!",
  },
  // {
  //   title: "WhisperScript 2",
  //   description: "The Ultimate AI Transcription Software for your Mac",
  //   price: 10,
  //   image: waveryIcon,
  //   url: "/products/whisperscript",
  //   iconBg: "blue",
  //   tag: "Coming Soon!",
  // },
  // {
  //   title: "Wavery",
  //   description: "Powerful Audio AI Workflow Toolchain for Media Professionals",
  //   price: 20,
  //   image: waveryIcon,
  //   url: "/purchase/wavery",
  //   iconBg: "purple",
  //   tag: "coming soon!",
  // },
];

export const features = [
  {
    title: "Search Workflow",
    description:
      "Quickly search through your audio to find just what you are looking for.",
    image: waveryWorkflowZoom,
  },
  {
    title: "Drag & Drop Workflow",
    description:
      "Easily drag and drop your audio files into the app to start transcribing.",
    image: waveryWorkflowZoom,
  },
  {
    title: "Main Workflow",
    description:
      "Efficiently manage your audio transcriptions with WhisperScript’s powerful features.",
    image: waveryWorkflowZoom,
  },
];

export const plans = [
  {
    id: "whisperscriptMonthly",
    name: "Monthly",
    price: "9",
    billingCycle: " / month",
    subtitle: "For Short-term Projects",
    mode: "subscription",
    perks: [
      <>
        <strong>7 Day</strong> Trial
      </>,
      <>
        <strong>Unlimited</strong> Transcription
      </>,
      <>
        <strong>On-Device</strong> Processing
      </>,
      <>
        <strong>Easy</strong> Editing Tools
      </>,
      <>
        <strong>Instant</strong> Audio Playback
      </>,
      <>
        <strong>Batch</strong> Processing
      </>,
      <>
        <strong>99 Languages</strong> Supported
      </>,
      <>
        <strong>Multi-Format</strong> Exports
      </>,
      <>
        <strong>Latest</strong> Features
      </>,

      <>
        <strong>Discord</strong> Access
      </>,
    ],
  },
  {
    id: "whisperscriptLifetime",
    name: "Lifetime",
    price: "48",
    billingCycle: " lifetime",
    subtitle: "Limited Cyber Week Sale!",
    featured: true,
    mode: "payment",
    perks: [
      <>
        <strong>Unlimited</strong> Transcription
      </>,
      <>
        <strong>On-Device</strong> Processing
      </>,
      <>
        <strong>Easy</strong> Editing Tools
      </>,
      <>
        <strong>Instant</strong> Audio Playback
      </>,
      <>
        <strong>Batch</strong> Processing
      </>,
      <>
        <strong>99 Languages</strong> Supported
      </>,
      <>
        <strong>Multi-Format</strong> Exports
      </>,
      <>
        <strong>Lifetime</strong> Access
      </>,
      <>
        <strong>One-Time</strong> Payment
      </>,
      <>
        <strong>Super Fast</strong> Support
      </>,
    ],
  },
  // {
  //   id: "whisperscriptAnnual",
  //   name: "Annual",
  //   price: "99",
  //   billingCycle: "/ year",
  //   subtitle: "One Month Free",
  //   mode: "subscription",
  //   perks: [
  //     <>
  //       One Month <strong>Free</strong>!
  //     </>,
  //     <>
  //       <strong>All Perks</strong> of Monthly Plan +
  //     </>,
  //     <>
  //       <strong>Member's Discord</strong> Access
  //     </>,
  //     <>
  //       <strong>Priority</strong> Support
  //     </>,
  //     <>
  //       <strong>7 Day</strong> Trial
  //     </>,
  //   ],
  // },
];
