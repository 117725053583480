import React, { useState } from "react";
import Button from "./Button";
import LoadingIcon from "./LoadingIcon";
import { useAuth } from "./../util/auth";
import GoogleIcon from "./../assets/web_light_rd_na.svg";
import AppleIcon from "../assets//Logo - SIWA - Left-aligned - Black - Large@2x.png";
import GithubIcon from "../assets/github-mark.svg";

function AuthSocial(props) {
  const auth = useAuth();
  const [pending, setPending] = useState(null);
  const redirectTo = props.afterAuthPath || "/dashboard";

  const providerDisplayNames = {
    google: "Google",
    facebook: "Facebook",
    twitter: "Twitter",
    github: "GitHub",
    apple: "Apple",
  };

  const onSigninWithProvider = (provider, redirectTo) => {
    setPending(provider);
    auth
      .signinWithProvider(provider, redirectTo)
      .then((user) => {
        // Remember this provider was last used
        // so we can indicate for next login.
        localStorage.setItem("lastUsedAuthProvider", provider);
        props.onAuth(user);
      })
      .catch((error) => {
        setPending(null);
        props.onError(error.message);
      });
  };

  // Function to get the correct icon based on the provider
  const getProviderIcon = (provider) => {
    switch (provider) {
      case "google":
        return GoogleIcon;
      case "apple":
        return AppleIcon;
      case "github":
        return GithubIcon;
      default:
        return null; // Return null or a default icon if needed
    }
  };

  return (
    <div className="mt-8 space-y-2">
      {props.providers.map((provider) => (
        <div className="mb-2" key={provider}>
          <Button
            size="lg"
            variant="simple"
            disabled={pending === provider}
            isBlock={true}
            placement={`AuthSocial-${provider}`}
            onClick={() => {
              onSigninWithProvider(provider, redirectTo);
            }}
            startIcon={
              pending !== provider && (
                <img
                  src={getProviderIcon(provider)}
                  alt={providerDisplayNames[provider]}
                  className="w-5 h-5"
                />
              )
            }
          >
            {pending === provider && <LoadingIcon className="w-6" />}

            {pending !== provider && (
              <>
                {props.buttonAction} with {providerDisplayNames[provider]}
              </>
            )}
          </Button>
        </div>
      ))}
    </div>
  );
}

export default AuthSocial;

//{`../assets/icon-${provider}.svg`}
