import React from "react";
import Meta from "./../components/Meta";
import HeroSection from "./../components/HeroSection";
import TeamBiosSection2 from "./../components/TeamBiosSection2";
import CtaSection from "./../components/CtaSection";
import { berlin01, berlin02, wave01 } from "../assets";
import EXISTSection from "../components/EXISTSection";

function AboutPage(props) {
  return (
    <>
      <Meta title="About" description="Learn about our company and team" />
      <HeroSection
        title={
          <>
            We are <span className="font-light text-waveryBlue">Wavery</span>
          </>
        }
        subtitle="Innovating and Integrating AI into Audio Post-Production with Privacy and Precision"
        strapline="Made with ❤️ in Berlin"
        size="md"
        bgColor="bg-primary"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-gray-100"
        leftImage={wave01}
        centerImage={berlin01}
        rightImage={berlin02}
      />
      <EXISTSection
        title="Gefördert durch:"
        subtitle="Die Europäische Union fördert zusammen mit dem Bundesministerium für Wirtschaft und Klimaschutz über den Europäischen Sozialfonds plut (ESF Plus) das Programm 'Existenzgründungen aus der Wissenschaft (EXIST)' in Deutschland."
        strapline="Aufgrund eines Beschlusses des Deutschen Bundestages"
        size="md"
        bgColor="bg-primary"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-gray-100"
      />
      <TeamBiosSection2
        title="Meet our Team"
        subtitle="Dedicated to Innovating the Future of Audio Post-Production"
        strapline="The Minds Behind Wavery"
        size="md"
        bgColor="bg-primary"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-gray-100"
      />
      <CtaSection
        title={
          <>
            Join Us in Revolutionizing{" "}
            <span className="text-waveryBlue">Transcription </span>
            Today!
          </>
        }
        subtitle="Experience the future of audio editing and transcription with Wavery’s cutting-edge tools."
        strapline=""
        size="md"
        bgColor="bg-primary"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-gray-100"
        buttonLink="/pricing"
        buttonText="Get Started Today!"
        id={"AboutPage"}
      />
    </>
  );
}

export default AboutPage;
