import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useRouter } from "./../util/router.js";

function Meta(props) {
  const { children, ...customPageMeta } = props;
  const router = useRouter();

  // Meta values that are the same across all pages
  const globalMeta = {
    siteName: "Wavery",
    domain: window.origin,
    twitterHandle: "@getwavery",
  };

  // Default meta values for current page (override with props)
  const defaultPageMeta = {
    title: "Wavery - The Ultimate Transcription Toolkit",
    description:
      "World-class transcription UI with unparalleled Accuracy and Privacy.",
    image: "/images/Wavery_Icon.png", // Social share image (create this file in /public/images/)
    type: "website", // Page type (see https://ogp.me/#types)
  };

  // Construct meta object from global, default, and custom meta
  const meta = { ...globalMeta, ...defaultPageMeta, ...customPageMeta };

  /* 
    NOTE: Set document.title programmatically instead of returning it as <title>
    • Important for analytics.page() to get the latest title value on page change
  */
  useEffect(() => {
    document.title = meta.title;
  }, [meta.title]);

  // Structured Data for Google
  const jsonLdContent = [
    // organization
    {
      "@context": "https://schema.org",
      "@type": "Organization",
      url: meta.domain,
      sameAs: [
        "https://getwavery.gumroad.com",
        "https://www.facebook.com/getwavery",
        "https://www.instagram.com/getwavery",
        "https://x.com/getwavery",
      ],
      logo: `${meta.domain}${meta.image}`,
      image: `${meta.domain}/images/app-screenshot.webp`,
      name: "Wavery",
      description:
        "Wavery is a leading provider of AI-powered transcription tools.",
      email: "support@getwavery.com",
      telephone: "+43-6609428552",
      address: {
        "@type": "PostalAddress",
        streetAddress: "Siegfriedstr. 11",
        addressLocality: "Berlin",
        addressCountry: "DE",
        postalCode: "12051",
      },
    },
    // product
    {
      "@context": "https://schema.org",
      "@type": "SoftwareApplication",
      name: "WhisperScript",
      operatingSystem: "OSX 13.3",
      applicationCategory: "MultimediaApplication",
      offers: {
        "@type": "Offer",
        price: 48,
        priceCurrency: "EUR",
      },
    },
    // FAQ
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "What is WhisperScript?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "WhisperScript is a macOS app for on-device transcription using cutting-edge models that support 99 languages. It includes editing tools, batch processing, and export options in TXT, CSV, SRT, and VTT formats — all with local processing to ensure your data stays private.",
          },
        },
        {
          "@type": "Question",
          name: "Is there a limit to how much audio I can transcribe?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "No, there's no limit! With WhisperScript, you can transcribe as many hours of audio as you need. It's perfect for handling everything from short clips to full-length interviews and lectures.",
          },
        },
        {
          "@type": "Question",
          name: "What languages does WhisperScript support?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "WhisperScript supports transcription in 99 languages, including English, Spanish, Chinese, Russian, and many more. You can check the full list of supported languages in the app's settings.",
          },
        },
        {
          "@type": "Question",
          name: "Can I edit the transcript directly within WhisperScript?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Absolutely! WhisperScript includes built-in editing tools that let you correct and refine your transcripts directly within the app. Simply click on the text to make changes, and they'll sync with the audio playback for easy reviewing.",
          },
        },
        {
          "@type": "Question",
          name: "Is there a subscription fee?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "WhisperScript offers two pricing options: a one-time payment that gives you access to a specific major version of the software, and a subscription plan that provides access to all the latest features and updates, as well as previous versions, for as long as your subscription is active.",
          },
        },
        {
          "@type": "Question",
          name: "Can I try WhisperScript before buying?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Yes! WhisperScript offers a free trial. Visit the 'Pricing' page and click 'Start Free Trial' to download the app and explore its core features before making a purchase.",
          },
        },
      ],
    },
  ];

  // Note: Each tag should have a unique `key` so that they are de-deduped if other
  // `Meta` components are rendered on the same page or within nested components.
  // prettier-ignore
  return (
    <Helmet>
      <meta name="description" content={meta.description} key="description" />
      <link rel="canonical" href={`${meta.domain}${router.pathname}${router.location.search}`} key="canonical" />

      {/* Open Graph */}
      <meta property="og:title" content={meta.title} key="og-title" />
      <meta property="og:description" content={meta.description} key="og-description" />
      <meta property="og:site_name" content={meta.siteName} key="og-site-name" />
      <meta property="og:type" content={meta.type} key="og-type" />
      <meta property="og:url" content={`${meta.domain}${router.pathname}${router.location.search}`} key="og-url" />
      <meta property="og:image" content={`${meta.domain}${meta.image}`} key="og-image" />

      {/* Twitter */}
      <meta name="twitter:title" content={meta.title} key="twitter-title" />
      <meta name="twitter:description" content={meta.description} key="twitter-description"/>
      <meta name="twitter:site" content={meta.twitterHandle} key="twitter-site" />
      <meta name="twitter:card" content="summary_large_image" key="twitter-card" />
      <meta name="twitter:image" content={`${meta.domain}${meta.image}`} key="twitter-image" />

      {/* Favicon */}
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" key="apple-touch-icon"/>
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" key="icon-32x32"/>
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" key="icon-16x16"/>
      <link rel="icon" sizes="any" href="/favicon.ico" key="icon-48x48"/>
      <link rel="manifest" href="/site.webmanifest" key="manifest"/>

      {/* Google Structured Data  */}
      <script type="application/ld+json">{JSON.stringify(jsonLdContent)}</script>
    </Helmet>
  );
}

export default Meta;
