import React from "react";
import Section from "./Section";
import LoadingIcon from "./LoadingIcon";

function PageLoader(props) {
  return (
    <Section size="lg">
      {!props.children && (
        <LoadingIcon className="w-10 mx-auto text-white stroke-white" />
      )}

      {props.children && <>{props.children}</>}
    </Section>
  );
}

export default PageLoader;
