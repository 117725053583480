/*
  A wrapper around React Router that adds a useRouter() hook so that any component
  can easily access params, location, history, and trigger navigation.
  Import from this file instead of react-router-dom directly.
*/

import React, { useMemo, useEffect } from "react";
import {
  BrowserRouter,
  useParams,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { AnalyticsProvider } from "use-analytics";
import { analyticsInstance } from "./analytics";
import queryString from "query-string";

// Use a custom history object and pass to Router so that we
// can utilize history.listen() where needed (such as for pageview tracking)

// Export our <Router> component
// Includes custom history object and component for auto-scrolling to top or to anchors
export function Router({ children }) {
  return (
    <AnalyticsProvider instance={analyticsInstance}>
      <BrowserRouter>
        <ScrollToTopOrAnchor />
        {children}
      </BrowserRouter>
    </AnalyticsProvider>
  );
}

// Custom useRouter hook for getting route data and methods inside any component.
// NOTE: This hook includes all React Router hooks, which can result in extra re-renders
// in some cases. When needed, you can optimize performance by importing the specific hook
// you need (such as useParams or useLocation) instead of this custom useRouter hook.
export function useRouter() {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // Return our custom router object
  // Memoize so that a new object is only returned if something changes
  return useMemo(() => {
    return {
      params,
      location,
      // For convenience add push(), replace(), pathname at top level
      push: (path) => navigate(path),
      replace: (path) => navigate(path, { replace: true }),
      pathname: location.pathname,
      // Merge params and parsed query string into single "query" object
      // so that they can be used interchangeably.
      // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
      query: {
        ...queryString.parse(location.search), // Convert string to object
        ...params,
      },
    };
  }, [params, location, navigate]);
}

// Modified ScrollToTop component to handle scrolling to anchors
function ScrollToTopOrAnchor() {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (hash) {
      // If there is a hash, scroll to the anchor
      const anchorId = hash.replace("#", "");
      const element = document.getElementById(anchorId);
      if (element) {
        // Use smooth scrolling if supported
        element.scrollIntoView({ behavior: "smooth" });
      } else {
        // If the element is not found, scroll to top as a fallback
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    } else {
      // No hash, scroll to top when pathname changes
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [pathname, hash]);

  return null;
}

export {
  Routes,
  Route,
  Link,
  NavLink,
  useParams,
  useLocation,
} from "react-router-dom";
