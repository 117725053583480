// import React, { useState } from "react";
import FormAlert from "./FormAlert";
// import EditItemModal from "./EditItemModal";
import { useAuth } from "./../util/auth";
// import { useItemsByOwner } from "./../util/db";
import { SparklesIcon } from "@heroicons/react/24/solid";

function DashboardItems(props) {
  const auth = useAuth();
  const products = auth.user.products;
  const itemsAreEmpty = !products || products.length === 0;

  return (
    <>
      {itemsAreEmpty && (
        <div className="mb-4">
          <FormAlert
            type="error"
            message={"It looks like you don't have any Wavery products."}
          />
        </div>
      )}

      <div>
        <div className="flex items-center justify-between p-4">
          <span className="text-xl">
            <strong>Your Wavery Products</strong>
          </span>
        </div>

        {products && products.length > 0 && (
          <div className="overflow-x-auto">
            <table className="min-w-full text-gray-300 rounded-lg shadow-md bg-primary">
              <thead className="text-white bg-gray-800">
                <tr>
                  <th className="w-6 py-2"></th>
                  <th className="w-1/5 px-4 py-2">Product Name</th>
                  <th className="w-1/5 px-4 py-2">Version</th>
                  <th className="w-1/5 px-4 py-2">Active</th>
                  <th className="w-1/5 px-4 py-2">Purchase Date</th>
                  <th className="w-auto px-4 py-2">License Key</th>
                </tr>
              </thead>
              <tbody className="text-gray-300">
                {products.map((product, index) => (
                  <tr key={index} className="">
                    <td className="px-4 py-2">
                      <SparklesIcon className="w-6" />
                    </td>
                    <td className="px-4 py-2">{product.name}</td>
                    <td className="px-4 py-2">{product.appVersion}</td>
                    <td className="px-4 py-2">
                      {product.hasAccess ? "Active" : "Inactive"}
                    </td>
                    <td className="px-4 py-2">
                      {new Date(product.createdAt).toLocaleDateString()}
                    </td>
                    <td className="w-auto px-4 py-2">{product.id}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
}

export default DashboardItems;
