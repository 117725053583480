import { ArrowRightIcon } from "@heroicons/react/24/solid";
import React from "react";
import { waveryWorkflowZoom } from "../assets";
import { layout } from "../styles/style";
import { Link } from "./../util/router";
import Button from "./Button";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

function HeroSection3(props) {
  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
      anchor={props.anchor}
    >
      <div className="container flex flex-col space-y-3 text-center lg:flex-row-reverse lg:space-y-0 lg:text-left">
        <div className="lg:w-1/2 lg:self-center">
          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            strapline={props.strapline}
          />
          <div className="flex flex-col items-center justify-center pt-6 pb-10 space-y-2 sm:pb-16 sm:flex-row sm:items-center lg:justify-start sm:space-y-0 sm:space-x-2">
            {/* <a href="https://getwavery.gumroad.com/l/whisperscript"> */}
            <Button
              component={Link}
              to="/pricing"
              size="xl"
              placement={"HeroSection3-CTA"}
              endIcon={
                <ArrowRightIcon className="inline-block w-5 h-5 opacity-70" />
              }
            >
              Start Transcribing Now
            </Button>
            {/* </a> */}
            <a href={`${window.location.origin}/#features`}>
              <Button
                // component={Link}
                // to="/#features"
                size="xl"
                placement={"HeroSection2-learnMore"}
                variant="light"
                className="w-fit"
              >
                Learn more
              </Button>
            </a>
          </div>
        </div>
        <div className="pb-12 lg:w-1/2 lg:mr-16 lg:flex lg:justify-center lg:items-center md:pb-0">
          <div className="relative h-full">
            {/* VIDEO */}
            <div className={`${layout.sectionImgReverse}`}>
              <div
                className="z-10 overflow-hidden aspect-[--aspect-ratio] h-full relative rounded-[calc(6%/var(--aspect-ratio))/6%] border border-[hsla(0,0%,100%,0.08)] shadow-[0_3px_40px_20px_hsla(0,0%,100%,0.04),inset_0_0.5px_0_0_hsla(0,0%,100%,0.3)] [background:radial-gradient(85.77%_49.97%_at_51%_5.12%,hsla(0,100%,100%,0.15)0,rgba(222,226,255,0.1)45.83%,rgba(241,242,255,0.1)100%),rgba(0,0,0,0.44)] [transform:translateZ(0)]"
                style={{ "--aspect-ratio": 1818 / 1080 }}
              >
                <video
                  src={waveryWorkflowZoom}
                  alt="whisperscript-transcribing"
                  type="video/mp4"
                  className="w-full h-auto"
                  autoPlay
                  muted
                  loop
                  playsInline
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default HeroSection3;
