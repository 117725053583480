// analytics.js
import Analytics from "analytics";
import segmentPlugin from "@analytics/segment";

// Initialize analytics and plugins
// Documentation: https://getanalytics.io
const analyticsInstance = Analytics({
  debug: process.env.NODE_ENV !== "production",
  app: "WhisperScript",
  plugins: [
    // Instructions: https://divjoy.com/docs/segment
    segmentPlugin({
      writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY,
    }),
  ],
});

function trackEvent(eventName, params) {
  analyticsInstance.track(eventName, {
    // action: eventName,
    ...params,
  });
}

function trackPageView(params = {}) {
  analyticsInstance.page(...params);
}

function trackUser(userId) {
  analyticsInstance.identify(userId);
}

export { analyticsInstance, trackEvent, trackPageView, trackUser };
