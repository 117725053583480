import React from "react";
import Meta from "./../components/Meta";
import ContactSection2 from "./../components/ContactSection2";

function ContactPage(props) {
  return (
    <>
      <Meta title="Contact" />
      <ContactSection2
        title="Get in touch"
        subtitle="Got a question, suggestion, or just want to say hi? We’re here to listen."
        strapline="Let's Chat!"
        size="lg"
        bgColor="bg-primary"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-gray-100"
      />
    </>
  );
}

export default ContactPage;
