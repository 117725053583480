import React, { useState } from "react";
import { useForm } from "react-hook-form";
import TextField from "./TextField";
import Button from "./Button";
import LoadingIcon from "./LoadingIcon";
import { useAuth } from "./../util/auth";

function AuthForm(props) {
  const auth = useAuth();

  const [pending, setPending] = useState(false);

  const { gumroadLicenseKey, gumroadEmail } = props.queryParams;

  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors },
  } = useForm();

  const submitHandlersByType = {
    signin: ({ email, pass, params }) => {
      return auth.signin(email, pass, params).then((user) => {
        // Call auth complete handler
        props.onAuth(user);
      });
    },
    signup: ({ email, pass, params }) => {
      // const redirectTo = `${window.location.origin}/about`;

      return auth.signup(email, pass, params).then((user) => {
        // Call auth complete handler
        props.onAuth(user);
      });
    },

    forgotpass: ({ email }) => {
      return auth.sendPasswordResetEmail(email).then(() => {
        setPending(false);
        // Show success alert message
        props.onFormAlert({
          type: "success",
          message: "Password reset email sent",
        });
      });
    },
    changepass: ({ pass }) => {
      return auth.confirmPasswordReset(pass).then(() => {
        setPending(false);
        // Show success alert message
        props.onFormAlert({
          type: "success",
          message: "Your password has been changed",
        });
      });
    },
  };

  // Handle form submission
  const onSubmit = ({ email, pass, licenseKey }) => {
    // Show pending indicator
    setPending(true);

    // Prepare options, overriding gumroadLicenseKey with user-inputted value
    const params = {
      ...props.queryParams,
      gumroadLicenseKey: licenseKey || props.queryParams.gumroadLicenseKey,
    };

    // Call submit handler for auth type
    submitHandlersByType[props.type]({
      email,
      pass,
      params,
    }).catch((error) => {
      setPending(false);
      console.log("🚀 ~ AuthError ~ error", error);
      // Show error alert message
      props.onFormAlert({
        type: "error",
        message: error.message,
      });
    });
  };

  return (
    <div className="">
      <form
        className="space-y-3 text-gray-900"
        onSubmit={handleSubmit(onSubmit)}
      >
        {[
          "signup",
          "signin",
          "forgotpass",
          // "electron",
          // "electronSignup",
          // "electronMigrateGumroad",
        ].includes(props.type) && (
          <TextField
            type="email"
            id="email"
            name="email" //potential replace by id param
            placeholder="Email"
            defaultValue={gumroadEmail || ""}
            error={errors.email}
            {...register("email", { required: "Please enter an email" })}
          />
        )}
        {[
          "signup",
          "signin",
          "changepass",
          // "electron",
          // "electronSignup",
          // "electronMigrateGumroad",
        ].includes(props.type) && (
          <TextField
            type="password"
            id="pass"
            name="pass" //potential replace by id param
            placeholder="Password"
            error={errors.pass}
            {...register("pass", { required: "Please enter a password" })}
          />
        )}
        {[
          "signup",
          "changepass",
          // "electronSignup",
          // "electronMigrateGumroad",
        ].includes(props.type) && (
          <TextField
            type="password"
            id="confirmPass"
            name="confirmPass" //potential replace by id param
            placeholder="Confirm Password"
            error={errors.confirmPass}
            {...register("confirmPass", {
              required: "Please enter your password again",
              validate: (value) => {
                if (value === getValues().pass) {
                  return true;
                } else {
                  return "This doesn't match your password";
                }
              },
            })}
          />
        )}
        {["signup"].includes(props.type) && (
          <div className="">
            <p className="py-3 text-sm text-gray-600">
              If you have a WhisperScript Pro Gumroad license key, you can enter
              it here.
            </p>
            <TextField
              type="text"
              id="licenseKey"
              name="licenseKey"
              placeholder="Gumroad License Key (Optional)"
              defaultValue={gumroadLicenseKey || ""}
              error={errors.licenseKey}
              {...register("licenseKey")}
            />
          </div>
        )}
        <Button
          type="submit"
          size="lg"
          disabled={pending}
          isBlock={true}
          placement={`Auth-${props.buttonAction}`}
        >
          {pending && <LoadingIcon className="w-6" />}
          {!pending && <>{props.buttonAction}</>}
        </Button>
      </form>
    </div>
  );
}

export default AuthForm;

// electron: ({ email, pass }) => {
//   return auth.signin(email, pass).then((user) => {
//     // Call auth complete handler
//     props.onAuth(user);
//   });
// },
// electronSignup: ({ email, pass, gumroadLicenseKey }) => {
//   const redirectTo = `${window.location.origin}/launchapp`;

//   return auth
//     .signup(email, pass, redirectTo, gumroadLicenseKey)
//     .then((user) => {
//       // Call auth complete handler
//       props.onAuth(user);
//     });
// },

// electronMigrateGumroad: ({ email, pass, gumroadLicenseKey }) => {
//   const redirectTo = `${window.location.origin}/launchapp`;

//   return auth
//     .handleElectronMigrateGumroad({
//       email,
//       pass,
//       redirectTo,
//       gumroadLicenseKey,
//     })

//     .then((user) => {
//       // Call auth complete handler
//       props.onAuth(user);
//     });
// },
