import WaveryLogo from "./WaveryLogo";

export default function WaveryAppIcon({ className = "", ...props } = {}) {
  return (
    <div
      {...props}
      className={`relative rounded-[22%] flex items-center justify-center p-3 mx-auto bg-gradient-to-b from-[#1E1E1E] to-60% to-[#161616] shadow-2xl hover:shadow-3xl hover:scale-[1.08] transition duration-700 overflow-hidden [box-shadow:inset_0_0.5px_0_0_rgba(255,255,255,0.17),0_6px_20px_5px_rgba(0,0,0,0.23)] drop-shadow-[0_20px_80px_rgba(66,127,132,1)] hover:drop-shadow-[0_0_70px_rgba(66,127,132,0.7)] transform-gpu ${className}`}
    >
      <WaveryLogo className="w-full h-full brightness-105 drop-shadow-[0_0_50px_rgba(0,0,0,0.6)] transform-gpu" />
    </div>
  );
}
