import React from "react";
import { StarIcon } from "@heroicons/react/24/solid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Avatar from "./Avatar";
import { testimonialPhoto01 } from "../assets";
// import AnimatedGradientBorderBox from "./AnimatedGradientBorderBox";

function TestimonialsSection2(props) {
  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="container space-y-16">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />

        {/* <div className="container"> */}
        <div className="relative rounded-md shadow-sm gray-border gray-background">
          {/* <AnimatedGradientBorderBox
            gradientColor="rgb(56 189 248 / .75)"
            gradientSize={70}
            duration={1200}
            className="flex items-center justify-center rounded-xl border border-white/5 p-[2px]"
            isLoading={true}
          > */}
          <div className="absolute top-0 right-0 mt-3 mr-5 font-serif text-blue-200 opacity-75 text-9xl">
            “
          </div>
          <div className="relative px-6 pt-16 pb-6 text-center md:px-12 md:pb-10">
            <div className="mb-3">
              <div className="inline-flex items-center px-2 py-1 text-yellow-500 bg-orange-100 rounded-lg">
                <StarIcon className="inline-block w-5 h-5" />
                <StarIcon className="inline-block w-5 h-5" />
                <StarIcon className="inline-block w-5 h-5" />
                <StarIcon className="inline-block w-5 h-5" />
                <StarIcon className="inline-block w-5 h-5" />
              </div>
            </div>
            <blockquote className="mx-auto lg:w-2/3 xl:w-1/2">
              <p className="mb-5 text-xl font-semibold leading-8">
                Whisperscript is a game-changer for my design research
                interviews. The AI-powered app quickly transcribes my audio
                recordings with impressive accuracy, saving me valuable time and
                allowing me to focus on the insights that matter.
              </p>
              <footer className="space-y-4">
                <Avatar
                  image={testimonialPhoto01}
                  size="md"
                  className="mx-auto"
                />
                <div>
                  <span className="text-lg font-semibold text-blue-600">
                    Danh P.
                  </span>
                  <div className="w-10 h-1 mx-auto my-1 bg-blue-200 rounded-lg" />
                  <p className="font-medium text-gray-500">
                    Project Pluto Studio.
                  </p>
                </div>
              </footer>
            </blockquote>
          </div>
          {/* </AnimatedGradientBorderBox> */}
        </div>
      </div>
    </Section>
  );
}

export default TestimonialsSection2;
