import React, { useState } from "react";
import {
  ChevronDownIcon,
  LifebuoyIcon,
  CogIcon,
  LockClosedIcon,
  Bars3Icon,
  XMarkIcon,
  QuestionMarkCircleIcon,
  RocketLaunchIcon,
  PlusCircleIcon,
  UserPlusIcon,
  UserIcon,
  // ViewColumnsIcon,
} from "@heroicons/react/24/solid";
// import { whisperScriptMainIcon } from "../assets/";
import { Menu, Transition } from "@headlessui/react";
import Section from "./Section";
import { Link } from "./../util/router";
import Button from "./Button";
import { useAuth } from "./../util/auth";
import { products } from "../constants/";
import NavBarProduct from "./NavBarProduct";
import WaveryLogo from "./WaveryLogo";

function Navbar(props) {
  const auth = useAuth();
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const classes = {
    navLink:
      "font-semibold text-gray-100 inline-flex items-center space-x-1 h-8 px-4 group-hover:text-cyan-300 py-6",
    navLinkIcon:
      "opacity-50 transform transition duration-200 ease-out group-hover:rotate-180 inline-block w-4 h-4",
    dropdown: {
      base: "absolute top-19 pt-1 z-10 invisible group-hover:visible transform transition duration-800 ease-in opacity-0 group-hover:opacity-100",
      left: "right-0",
      center: "left-1/2 -translate-x-1/2",
      right: "left-0",
      normal: "w-48",
      large: "w-96",
      inner:
        "gray-background gray-border shadow-xl ring-1 ring-black ring-opacity-5 rounded-lg overflow-hidden",
      title:
        "text-xl lg:text-xs md:text-sm uppercase font-semibold tracking-wider text-waveryBlue mb-5",
      link: "text-gray-300 hover:text-waveryBlue font-medium text-lg lg:text-sm flex items-center space-x-2",
      icon: "opacity-25 inline-block w-5 h-5 pr-1",
      feature:
        "p-3 rounded-xl flex items-center space-x-4 text-gray-600 font-medium text-sm",
      featureName: "font-semibold mb-1",
      featureDescription: "opacity-75",
    },
    accountDropdown: {
      base: "absolute right-0 origin-top-right mt-2 w-48 z-10",
      inner:
        "bg-white ring-1 ring-black ring-opacity-5 rounded divide-y divide-gray-100 shadow-xl rounded",
      link: "flex items-center space-x-2 rounded py-2 px-3 text-sm font-medium text-gray-600 hover:bg-gray-100 hover:text-gray-700",
      linkActive: "text-gray-700 bg-gray-100",
      linkInactive:
        "text-gray-600 hover:bg-gray-100 hover:text-gray-700 focus:outline-none focus:bg-gray-100 focus:text-gray-700",
      icon: "opacity-50 inline-block w-5 h-5",
    },
    waveryLogoText: "font-sans font-bold text-gray-100 transition-colors",
    productHeaderText: "Featured Products",
  };

  const menuItems = [
    {
      label: "Products",
      submenu: {
        title: classes.productHeaderText,
        items: products,
      },
    },
    {
      label: "Pricing",
      to: "/pricing",
    },
    {
      label: "Resources",
      submenu: {
        sections: [
          {
            title: "Quicklinks",
            items: [
              {
                label: "FAQ",
                to: "/faq",
                icon: QuestionMarkCircleIcon,
              },
              {
                label: "About Wavery",
                to: "/about",
                icon: RocketLaunchIcon,
              },
            ],
          },
          {
            title: "Support",
            items: [
              {
                label: "Contact Us",
                to: "/contact",
                icon: LifebuoyIcon,
              },
              {
                label: "Suggest Features",
                to: "/contact",
                icon: PlusCircleIcon,
              },
            ],
          },
        ],
      },
    },
  ];

  const accountLinks = {
    signedIn: [
      {
        label: "Settings",
        to: "/settings/general",
        icon: CogIcon,
      },
      {
        label: "Sign out",
        onClick: (e) => {
          e.preventDefault();
          auth.signout();
        },
        icon: LockClosedIcon,
      },
    ],
    signedOut: [
      {
        label: "Sign in",
        to: "/auth/signin",
        icon: UserIcon,
      },
      {
        label: "Sign up",
        to: "/auth/signup",
        icon: UserPlusIcon,
      },
    ],
  };

  const NavLink = ({ item }) => {
    if (item.submenu) {
      return (
        <li className="relative group">
          <span className={`${classes.navLink}`}>
            <span>{item.label}</span>
            <ChevronDownIcon className={classes.navLinkIcon} />
          </span>
          <div
            className={`${classes.dropdown.base} ${classes.dropdown.large} ${classes.dropdown.center}`}
          >
            <div className={`${classes.dropdown.inner}`}>
              {item.submenu.title && (
                <div className="px-3 pt-3">
                  <h4 className={`${classes.dropdown.title}`}>
                    {item.submenu.title}
                  </h4>
                </div>
              )}
              {item.submenu.items && (
                <nav className="flex flex-col p-3 space-y-1">
                  <NavBarProduct
                    products={item.submenu.items}
                    setMobileNavOpen={setMobileNavOpen}
                    classes={classes}
                  />
                </nav>
              )}
              {item.submenu.sections && (
                <div className="grid grid-cols-2">
                  {item.submenu.sections.map((section, index) => (
                    <div key={index} className="p-6 space-y-6">
                      <h4 className={`${classes.dropdown.title}`}>
                        {section.title}
                      </h4>
                      <nav className="flex flex-col space-y-3">
                        {section.items.map((subItem, subIndex) => (
                          <Link
                            key={subIndex}
                            to={subItem.to}
                            className={`${classes.dropdown.link}`}
                          >
                            {subItem.icon && (
                              <subItem.icon className={classes.dropdown.icon} />
                            )}
                            {subItem.label}
                          </Link>
                        ))}
                      </nav>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </li>
      );
    } else {
      return (
        <li className="relative group">
          <Link to={item.to} className={`${classes.navLink}`}>
            <span>{item.label}</span>
          </Link>
        </li>
      );
    }
  };

  const MobileMenu = () => (
    <div
      aria-label="Menu Dropdown"
      className={
        "lg:hidden fixed inset-0 z-50 overflow-y-auto [-webkit-backdrop-filter:blur(40px)] backdrop-blur-[40px] bg-[linear-gradient(-120deg,rgba(20,20,20,0.6),rgba(38,38,38,0.6)_80%)] shadow" +
        (!mobileNavOpen ? " hidden" : "")
      }
    >
      <div className="absolute top-4 right-4">
        <Button
          variant="dark"
          size="sm"
          placement={"Navbar-hamburgerClose"}
          onClick={() => setMobileNavOpen(false)}
          endIcon={<XMarkIcon className="inline-block w-5 h-5" />}
        />
      </div>
      <div className="p-6 space-y-6">
        {menuItems.map((item, index) => (
          <div key={index}>
            {item.submenu ? (
              <>
                {item.submenu.title && (
                  <h4 className={`${classes.dropdown.title}`}>
                    {item.submenu.title}
                  </h4>
                )}
                {item.submenu.items && (
                  <nav className="flex flex-col space-y-3">
                    <NavBarProduct
                      products={item.submenu.items}
                      setMobileNavOpen={setMobileNavOpen}
                      classes={classes}
                    />
                  </nav>
                )}
                {item.submenu.sections &&
                  item.submenu.sections.map((section, sIndex) => (
                    <div key={sIndex}>
                      <h4 className={`${classes.dropdown.title} mb-0`}>
                        {section.title}
                      </h4>
                      <nav className="flex flex-col gap-2.5 pt-4 pb-8">
                        {section.items.map((subItem, subIndex) => (
                          <Link
                            key={subIndex}
                            to={subItem.to}
                            onClick={() => setMobileNavOpen(false)}
                            className={`${classes.dropdown.link}`}
                          >
                            {subItem.icon && (
                              <subItem.icon className={classes.dropdown.icon} />
                            )}
                            {subItem.label}
                          </Link>
                        ))}
                      </nav>
                    </div>
                  ))}
              </>
            ) : (
              <Link
                to={item.to}
                onClick={() => setMobileNavOpen(false)}
                className={`${classes.dropdown.link}`}
              >
                {item.label}
              </Link>
            )}
          </div>
        ))}
        <>
          <h4 className={`${classes.dropdown.title}`}>Account</h4>
          <nav className="flex flex-col gap-2.5 pt-4 pb-4">
            {!auth.user ? (
              <>
                {accountLinks.signedOut.map((link, index) => (
                  <Link
                    key={index}
                    to={link.to}
                    onClick={() => setMobileNavOpen(false)}
                    className={`${classes.dropdown.link}`}
                  >
                    <link.icon className={classes.dropdown.icon} />
                    {link.label}
                  </Link>
                ))}
              </>
            ) : (
              <>
                {accountLinks.signedIn.map((link, index) => (
                  <Link
                    key={index}
                    to={link.to || "#"}
                    onClick={(e) => {
                      if (link.onClick) {
                        e.preventDefault();
                        link.onClick(e);
                      }
                      setMobileNavOpen(false);
                    }}
                    className={`${classes.dropdown.link}`}
                  >
                    <link.icon className={classes.dropdown.icon} />
                    {link.label}
                  </Link>
                ))}
              </>
            )}
          </nav>
        </>
      </div>
    </div>
  );

  return (
    <Section bgColor={props.bgColor}>
      <div className="container py-4 z-50">
        <div className="flex items-center justify-between">
          <Link
            to={window.location.origin}
            className="inline-flex items-center space-x-2 text-lg font-bold tracking-wide text-gray-300 hover:text-waveryBlue"
          >
            <WaveryLogo className="w-11 h-11" />
            {/* <img
              src={whisperScriptMainIcon}
              alt="Wavery-icon"
              className="w-[34px] h-[34px] rounded-full border border-gray-600"
            /> */}
            <div
              className={`${classes.waveryLogoText} sm:text-[28px] text-[26px] sm:ml-1 ml-2`}
            >
              <span>WAVERY</span>
            </div>
          </Link>
          <div className="items-center hidden space-x-4 lg:flex">
            <ul className="flex items-center space-x-4">
              {menuItems.map((item, index) => (
                <NavLink key={index} item={item} />
              ))}
              {!auth.user && (
                <li className="relative group">
                  <Link to="/auth/signin" className={`${classes.navLink}`}>
                    <span>Sign in</span>
                  </Link>
                </li>
              )}
            </ul>
            {auth.user && (
              <Menu as="div" className="relative inline-block">
                <Button
                  component={Menu.Button}
                  variant="simple"
                  size="sm"
                  placement={"Navbar-accountDropdown"}
                  onClick={() => setMobileNavOpen(false)}
                  endIcon={
                    <ChevronDownIcon className="inline-block w-5 h-5 opacity-50" />
                  }
                >
                  Account
                </Button>
                <Transition
                  as={React.Fragment}
                  enter="transition ease-out duration-150"
                  enterFrom="transform opacity-0 scale-75"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-100"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-75"
                >
                  <Menu.Items className={classes.accountDropdown.base}>
                    <div className={`${classes.accountDropdown.inner}`}>
                      {(auth.user
                        ? accountLinks.signedIn
                        : accountLinks.signedOut
                      ).map((link, index) => (
                        <div key={index} className="p-2 space-y-1">
                          <Menu.Item>
                            {link.to ? (
                              <Link
                                to={link.to}
                                className={`${classes.accountDropdown.link}`}
                              >
                                <link.icon
                                  className={classes.accountDropdown.icon}
                                />
                                <span>{link.label}</span>
                              </Link>
                            ) : (
                              <Link
                                to="#"
                                className={`${classes.accountDropdown.link}`}
                                onClick={link.onClick}
                              >
                                <link.icon
                                  className={classes.accountDropdown.icon}
                                />
                                <span>{link.label}</span>
                              </Link>
                            )}
                          </Menu.Item>
                        </div>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            )}
          </div>
          <div className="lg:hidden">
            <Button
              variant="dark"
              size="sm"
              placement={"Navbar-hamburgerOpen"}
              onClick={() => setMobileNavOpen(!mobileNavOpen)}
              endIcon={<Bars3Icon className="inline-block w-5 h-5" />}
            />
          </div>
        </div>
        <MobileMenu />
      </div>
    </Section>
  );
}

export default Navbar;
