import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  createContext,
} from "react";
import queryString from "query-string";
import supabase from "./supabase";
import { useUser, updateUser, createProduct } from "./db";
import { useRouter } from "./router";
import PageLoader from "./../components/PageLoader";
// import { getFriendlyPlanId } from "./prices";
import { useAnalytics } from "use-analytics";
import { trackEvent, trackUser } from "./analytics";

import gumroad from "./gumroad";

// Whether to merge extra user data from database into `auth.user`
const MERGE_DB_USER = true;

// Whether to connect analytics session to `user.uid`
const ANALYTICS_IDENTIFY = true;

// Create a `useAuth` hook and `AuthProvider` that enables
// any component to subscribe to auth and re-render when it changes.
const authContext = createContext();
export const useAuth = () => useContext(authContext);
// This should wrap the app in `src/pages/_app.js`
export function AuthProvider({ children }) {
  const auth = useAuthProvider();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook that creates the `auth` object and handles state
// This is called from `AuthProvider` above (extracted out for readability)
function useAuthProvider() {
  // Store auth user in state
  // `user` will be object, `null` (loading) or `false` (logged out)
  const [user, setUser] = useState(null);

  // Merge extra user data from the database
  // This means extra user data (such as payment plan) is available as part
  // of `auth.user` and doesn't need to be fetched separately. Convenient!
  let finalUser = useMergeExtraData(user, { enabled: MERGE_DB_USER });

  // Add custom fields and formatting to the `user` object
  finalUser = useFormatUser(finalUser);

  // Connect analytics session to user
  useIdentifyUser(finalUser, { enabled: ANALYTICS_IDENTIFY });

  // Handle response from auth functions (`signup`, `signin`, and `signinWithProvider`)
  const handleAuth = async (response) => {
    const {
      data: { user },
    } = response;

    // If email is unconfirmed throw error to be displayed in UI
    // The user will be confirmed automatically if email confirmation is disabled in Supabase settings
    if (!user.email_confirmed_at) {
      throw new Error(
        "Thanks for signing up! Please confirm your account in the link sent to your email to complete the process."
      );
    }

    // Identify the user with analytics
    trackUser(user.id);

    // Update user in state
    setUser(user);
    return user;
  };

  const validateGumroadLicense = async (licenseKey) => {
    try {
      // Get Gumroad user data
      const gumroadData = await gumroad.gumroadResponse(licenseKey);

      // If Gumroad user data is invalid or missing
      if (!gumroadData || !gumroadData.success) {
        throw new Error(
          gumroadData?.message ||
            "Invalid WhisperScript Pro license or unable to fetch Gumroad data. Please contact support."
        );
      }

      return gumroadData;
    } catch (error) {
      console.error("Error validating Gumroad license", error);
      throw error;
    }
  };

  const migrateFromGumroad = async (licenseKey, redirectTo) => {
    const gumroadData = await validateGumroadLicense(licenseKey);

    // Extract necessary data from gumroadData.purchase
    const purchase = gumroadData.purchase || {};

    //Set default values if missing data
    const gumroadEmail = purchase.email || null;
    const full_name = purchase.full_name || null;
    const sale_timestamp =
      purchase.sale_timestamp || purchase.created_at || null;
    const purchase_price = purchase.price || null;
    const variants = purchase.variants || "";
    const custom_fields = purchase.custom_fields || [];
    const referrer = purchase.referrer || null;
    const license_id = purchase.license_key || licenseKey;
    const sale_id = purchase.sale_id || null;
    const can_contact = purchase.can_contact ?? true;
    const is_following = purchase.is_following ?? true;

    // Determine if the user is Pro based on variants or other criteria
    const isPro = ["Pro", "WhisperScript 2"].some((variant) =>
      variants.includes(variant)
    );

    const appVersion = "2.0.0";
    const appName = "WhisperScript";

    // Construct the options object into auth.users.raw_users_metadata
    const options = {
      emailRedirectTo: redirectTo,
      data: {
        full_name: full_name,
        isGumroadUser: true,
        isGumroadProUser: isPro,
        canContact: can_contact,
        isFollowing: is_following,
        waveryUsername: full_name,
        releaseChannel: "latest",
        concurrentSessions: 2,
        appVersion: appVersion,
        appName: appName,
        gumroadData: {
          gumroadEmail: gumroadEmail,
          gumroadReferrer: referrer,
          gumroadPurchaseDate: sale_timestamp,
          gumroadLicenseKey: licenseKey,
          gumroadLicenseID: license_id,
          gumroadPurchasePrice: purchase_price,
          gumroadVariant: variants,
          gumroadSaleID: purchase.sale_id || null,
          gumroadState: purchase.state || null,
          gumroadZipCode: purchase.zip_code || null,
          gumroadCountryISO2: purchase.ip_country || null,
          gumroadCustomFields: custom_fields,
          gumRoadSaleId: sale_id,
          gumroadDataFull: purchase,
        },
      },
    };

    return options;
  };

  const insertProductFromGumroadKey = async (data) => {
    const { licenseKey } = data;

    // Ensure licenseKey is provided
    if (!licenseKey) {
      throw new Error("License key is required.");
    }

    try {
      const gumroadData = await validateGumroadLicense(licenseKey);
      if (
        gumroadData.success &&
        (gumroadData.purchase.variants === "(WhisperScript Pro)" ||
          gumroadData.purchase.variants === "(WhisperScript 2)")
      ) {
        const productData = {
          owner: user.id,
          email: user.email,
          name: gumroadData.purchase.variants, //"(WhisperScript Pro)",
          hasAccess: true,
          appName: "WhisperScript",
          appVersion: "2.0.0",
          concurrentSessionsRemaining: 2,
          releaseChannel: "latest",
          isTrialing: false,
          gumroadLicenseKey: data.licenseKey,
          gumroadData: gumroadData,
        };

        trackEvent("gumroadLicenseValid", {
          licenseKey: licenseKey,
          gumroadData: gumroadData,
        });
        await createProduct(productData);
      } else {
        trackEvent("gumroadLicenseInvalid", {
          licenseKey: licenseKey,
          gumroadData: gumroadData,
        });
        throw new Error(
          "Invalid license key or product variant. Please contact support."
        );
      }
    } catch (error) {
      trackEvent("gumroadLicenseError", {
        licenseKey: licenseKey,
        error: error,
      });
      throw error;
      // new Error("Invalid license key or product variant.");
    }
  };

  const signup = async (email, password, params) => {
    let options;

    if (params.gumroadLicenseKey) {
      const redirectTo = `${window.location.origin}${params.next}`;

      try {
        options = await migrateFromGumroad(
          params.gumroadLicenseKey,
          redirectTo
        );
        trackEvent("migrate", { method: "gumroad", ...options });
      } catch (error) {
        trackEvent("signupError", { method: "gumroad", error: error });
        throw error;
      }
    } else {
      const redirectTo = `${window.location.origin}/pricing`;
      // No Gumroad license key, use default options
      options = {
        emailRedirectTo: redirectTo,
        data: {
          full_name: null,
          isGumroadUser: false,
          isGumroadProUser: false,
          canContact: true,
          isFollowing: true,
          waveryUsername: null,
          releaseChannel: "latest",
          concurrentSessions: 2,
          appVersion: null,
          appName: null,
          gumroadData: null,
          isTrialing: false,
        },
      };
    }

    //Sign up with Options
    return supabase.auth
      .signUp({
        email: email,
        password: password,
        options: options,
      })
      .then(handleError)
      .then(handleAuth)
      .then(() => trackEvent("signup", { method: "password", ...options }));
  };

  const signin = (email, password) => {
    return supabase.auth
      .signInWithPassword({ email, password })
      .then(handleError)
      .then(handleAuth)
      .then(() => trackEvent("signin", { method: "password" }));
  };

  const signinWithProvider = (name, redirectTo) => {
    const hasAccess = user?.products?.find(
      (product) =>
        product.appName === "WhisperScript" && product.hasAccess === true
    );

    if (!hasAccess) {
      redirectTo = `/pricing?next=${redirectTo}`;
    }

    return (
      supabase.auth
        .signInWithOAuth({
          provider: name,
          options: {
            redirectTo: `${window.location.origin}${redirectTo}`,
          },
        })
        .then(handleError)
        // Because `signInWithOAuth` resolves immediately we need to add this so
        // it never resolves (component will display loading indicator indefinitely).
        // Once social signin is completed the page will redirect to value of `redirectTo`.
        // .then(async () => {
        //   console.log("🚀 ~ signinWithProvider ~ data", data);
        //   await updateUser("0a3fa550-e24a-4fde-af68-f638627f8840", options);
        // })
        .then(() => trackEvent(() => "signin", { method: name }))
        .then(() => {
          return new Promise(() => null);
        })
    );
  };

  const signinWithMagicLink = (email) => {
    const emailRedirectTo = `${window.location.origin}/dashboard`;
    return supabase.auth
      .signInWithOtp({
        email,
        options: {
          emailRedirectTo: emailRedirectTo,
        },
      })
      .then(handleError)
      .then(handleAuth)
      .then(() =>
        trackEvent("signin", {
          method: "magiclink",
          redirectTo: emailRedirectTo,
        })
      );
  };

  const signout = () => {
    return supabase.auth
      .signOut({ scope: "local" })
      .then(handleError)
      .then(() => trackEvent("signout", { scope: "website" }));
  };

  const sendPasswordResetEmail = (email) => {
    return supabase.auth
      .resetPasswordForEmail(email, {
        redirectTo: `${window.location.origin}/auth/changepass`,
      })
      .then(handleError)
      .then(() => trackEvent("passwordReset"));
  };

  const confirmPasswordReset = (password) => {
    return supabase.auth
      .updateUser({ password })
      .then(handleError)
      .then(() => trackEvent("confirmPasswordReset"));
  };

  const updatePassword = (password) => {
    return supabase.auth
      .updateUser({ password })
      .then(handleError)
      .then(() => trackEvent("updatePassword"));
  };

  // Update auth user and persist data to database
  // Call this function instead of multiple auth/db update functions
  const updateProfile = async (data) => {
    const { email, ...other } = data;

    // If email changed let them know to click the confirmation links
    // Will be persisted to the database by our Supabase trigger once process is completed
    if (email && email !== user.email) {
      await supabase.auth
        .updateUser({ email })
        .then(handleError)
        .then(() =>
          trackEvent("updateUserData", {
            newEmail: email,
            oldEmail: user.email,
          })
        );
      throw new Error(
        "To complete this process click the confirmation links sent to your new and old email addresses"
      );
    }

    // Persist all other data to the database
    if (Object.keys(other).length > 0) {
      await updateUser(user.id, other);
      trackEvent("updateUserData", { ...other });
    }
  };

  useEffect(() => {
    // Get hash portion of URL if coming from Supabase OAuth or magic link flow.
    // Store on `window` so we can access in other functions after hash is removed.
    window.lastHash = queryString.parse(window.location.hash);

    // If we have an `access_token` from OAuth or magic link flow avoid using
    // cached session so that user is `null` (loading state) until process completes.
    // Otherwise, a redirect to a protected page after social auth will redirect
    // right back to login due to cached session indicating they are logged out.
    if (!window.lastHash.access_token) {
      supabase.auth.getSession().then(({ data: { session } }) => {
        if (session) {
          setUser(session.user);
        } else {
          setUser(false);
        }
      });
    }

    // Subscribe to user on mount
    const {
      data: {
        subscription: { unsubscribe },
      },
    } = supabase.auth.onAuthStateChange((event, session) => {
      if (session) {
        setUser(session.user);
      } else {
        setUser(false);
      }
    });

    // Unsubscribe on cleanup
    return () => unsubscribe();
  }, []);

  return {
    user: finalUser,
    signup,
    signin,
    signinWithProvider,
    signinWithMagicLink,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset,
    updatePassword,
    updateProfile,
    insertProductFromGumroadKey,
  };
}

function useFormatUser(user) {
  // Memoize so returned object has a stable identity
  return useMemo(() => {
    // Return if auth user is `null` (loading) or `false` (not authenticated)
    if (!user) return user;

    // Create an array of user's auth providers by id (["password", "google", etc])
    // Components can read this to prompt user to re-auth with the correct provider
    let provider = user.app_metadata.provider;
    // Supabase calls it "email", but our components expect "password"
    if (provider === "email") provider = "password";
    const providers = [provider];

    // Get customer data
    // const customer = user.customers || {};
    // const products = user.products || {};

    return {
      // Include full auth user data
      ...user,
      // Alter the names of some fields
      uid: user.id,
      // User's auth providers
      providers: providers,
      // Add customer data
      // ...customer,
      // Add `planId` (starter, pro, etc) based on Stripe Price ID
      // ...(customer.stripePriceId &&
      //   {
      // planId: getFriendlyPlanId(customer.stripePriceId),
      // }),
      // Add `planIsActive: true` if subscription status is active or trialing
      // planIsActive: ["active", "trialing"].includes(
      //   products.stripeSubscriptionStatus
      // ),

      // If customer.stripeCustomerId is a truthy value (e.g., a non-empty string), !! converts it to true. If it's a falsy value (null, undefined, 0, '', etc.), !! converts it to false.
      // isStripeCustomer: !!customer.stripeCustomerId,
    };
  }, [user]);
}

function useMergeExtraData(user, { enabled }) {
  // Get extra user data from database
  const { data, status, error } = useUser(enabled && user && user.id);

  console.log(data);
  // Memoize so returned object has a stable identity
  return useMemo(() => {
    // If disabled or no auth user (yet) then just return
    if (!enabled || !user) return user;

    switch (status) {
      case "success":
        // If successful, but `data` is `null`, that means user just signed up and the `createUser`
        // function hasn't populated the db yet. Return `null` to indicate auth is still loading.
        // The above call to `useUser` will re-render things once the data comes in.
        if (data === null) return null;
        // Return auth `user` merged with extra user `data`
        return { ...user, ...data };
      case "error":
        // Uh oh.. Let's at least show a helpful error.
        throw new Error(`
          Error: ${error.message}
          This happened while attempting to fetch extra user data from the database
          to include with the authenticated user. Make sure the database is setup or
          disable merging extra user data by setting MERGE_DB_USER to false.
        `);
      default:
        // We have an `idle` or `loading` status so return `null`
        // to indicate that auth is still loading.
        return null;
    }
  }, [user, enabled, data, status, error]);
}

// Connect analytics session to current user
function useIdentifyUser(user, { enabled }) {
  const analytics = useAnalytics();
  useEffect(() => {
    if (user && enabled && analytics) {
      analytics.identify(user.uid);
    }
  }, [user, enabled, analytics]);
}

// A Higher Order Component for requiring authentication
export const requireAuth = (Component) => {
  return function RequireAuthHOC(props) {
    const router = useRouter();
    // Get authenticated user
    const auth = useAuth();

    useEffect(() => {
      // Redirect if not signed in
      if (auth.user === false) {
        router.replace("/auth/signin");
      }
    }, [auth, router]);

    // Show loading indicator
    // We're either loading (user is `null`) or about to redirect from above `useEffect` (user is `false`)
    if (!auth.user) {
      return <PageLoader />;
    }

    // Render component now that we have user
    return <Component {...props} />;
  };
};

// Throw error from auth response
// so it can be caught and displayed by UI
function handleError(response) {
  if (response.error) throw response.error;
  return response;
}

// eslint-disable-next-line
const getFromQueryString = (key) => {
  return queryString.parse(window.location.search)[key];
};

// Helper function for electronMigrateGumroad
// const handleElectronMigrateGumroad = async ({
//   email,
//   pass,
//   redirectTo,
//   gumroadLicense,
// }) => {
//   if (!email || !pass || !redirectTo || !gumroadLicense) {
//     throw new Error("Missing required parameters for Gumroad migration");
//   }

//   if (gumroadLicense) {
// console.log(
//   "🚀 ~ handleElectronMigrateGumroad ~ gumroadLicense",
//   gumroadLicense
// );
// }

// let gumroadRequestParams = {};
// let isPro = false;
// let appName = "";
// let appVersion = "";

// if (gumroadLicense) {
//   gumroadRequestParams = {
//     gumroadKey: gumroadLicense,
//     gumroadKeyType: "license",
//   };

// } else {
//   const { saleId, appName, appVersion } = electronProps;
//   gumroadRequestParams = {
//     gumroadKey: saleId,
//     gumroadKeyType: "sale",
//   };
// }

// try {
// 1. Fetch Gumroad sale data
// const gumroadUserData = await gumroad.gumroadResponse({ gumroadLicense });

// console.log(
//   "🚀 ~ handleElectronMigrateGumroad ~ gumroadUserData",
//   gumroadUserData,
//   appName,
//   appVersion
// );

// if (!gumroadUserData || !gumroadUserData.success) {
//   if (gumroadUserData.error) {
//     throw new Error(gumroadUserData.error);
//   }

//   if
//   // const isPro =
//     gumroadUserData.sale.variants_and_quantity === "(WhisperScript Pro)";
//   if (!isPro) {
//     throw new Error(
//       "Invalid WhisperScript Pro license or unable to fetch Gumroad data. Please contact support."
//     );
//   }
// }

//     return supabase.auth
//       .signUp({
//         email: email,
//         password: pass,
//         options: options,
//       })
//       .then(handleError)
//       .then(handleAuth);
//   } catch (error) {
//     console.log("Error migrating Gumroad user", error);
//     throw error;
//   }
// };
