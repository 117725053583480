import { ArrowRightIcon } from "@heroicons/react/24/solid";
import React from "react";
import { wave01, waverySurfingFlow } from "../assets";
import { Link } from "../util/router";
import Button from "./Button";
import MacOSScreen from "./MacOSScreen";
import Section from "./Section";
import SectionHeaderHero from "./SectionHeaderHero";
import WaveryAppIcon from "./WaveryAppIcon";

function HeroSection2(props) {
  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      bgImageClassName={props.bgImageClassName}
      textColor={props.textColor}
    >
      <div className="container relative">
        <div className="text-center">
          <Link
            to="/pricing"
            className="relative inline-block mb-14 sm:mb-[66px]"
          >
            <WaveryAppIcon className="w-[125px] h-[125px] sm:w-[150px] sm:h-[150px]" />
          </Link>
          <SectionHeaderHero
            title={props.title}
            titleLower={props.titleLower}
            subtitle={props.subtitle}
            strapline={props.strapline}
          />
        </div>

        <div className="flex flex-col items-center justify-center pt-10 pb-16 space-y-2 sm:flex-row sm:items-center sm:justify-center sm:space-y-0 sm:space-x-2">
          <Button
            component={Link}
            to="/pricing"
            size="xl"
            variant="primary"
            placement={"HeroSection2-CTA"}
            endIcon={
              <ArrowRightIcon className="inline-block w-5 h-5 opacity-70" />
            }
          >
            Start Free Trial Now
          </Button>
          <Button
            href={`${window.location.origin}/#features`}
            size="xl"
            variant="light"
            placement={"HeroSection2-learnMore"}
          >
            Learn more
          </Button>
        </div>
        <div className="pb-4 md:pb-0">
          <div className="relative mx-5 lg:mx-20">
            <div className="flex items-center justify-center w-full my-2">
              <MacOSScreen
                wallpaperSrc={wave01}
                className="flex items-center justify-center brightness-110"
              >
                <video
                  src={waverySurfingFlow}
                  type="video/mp4"
                  alt="whisperscript video"
                  className="z-10 rounded-b-[1%] rounded-t-[1.3%] aspect-[1266/1080] w-[min(92vw,750px)] shadow-[0_8px_30px_rgba(0,0,0,0.35)]"
                  autoPlay
                  loop
                  playsInline
                  muted
                />
              </MacOSScreen>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default HeroSection2;
