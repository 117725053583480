import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { existBMWK, existEU, existLogo } from "../assets";

function EXISTSection(props) {
  const icons = [
    {
      src: existBMWK,
      alt: "logo-bmwk",
      className:
        "w-32 h-32 object-contain transition-transform duration-300 ease-out group-hover:scale-105 will-change-transform",
    },
    {
      src: existEU,
      alt: "logo-EU",
      className:
        "w-32 h-32 object-contain transition-transform duration-300 ease-out group-hover:scale-105 will-change-transform",
    },
    {
      src: existLogo,
      alt: "logo-EXIST",
      className:
        "w-32 h-32 object-contain transition-transform duration-300 ease-out group-hover:scale-105 will-change-transform",
    },
  ];

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="container space-y-16">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />
        <div className="grid grid-cols-1 text-center sm:grid-cols-3 gap-3">
          {icons.map((icon, index) => (
            <div
              key={index}
              className="group flex flex-col items-center bg-gray-100 gray-border rounded-2xl py-1 sm:py-2 px-4"
            >
              <img src={icon.src} alt={icon.alt} className={icon.className} />
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
}

export default EXISTSection;
