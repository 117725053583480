import React, { useEffect, useState } from "react";
import Button from "./Button";
import Meta from "./Meta";
import Confetti from "react-confetti";
// import { useRouter } from "../util/router";
import Section from "./Section";
import { waveryWorkflowZoom } from "../assets";
import { layout } from "../styles/style";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import SectionHeader from "./SectionHeader";
import { discordIcon } from "../assets";
import { trackEvent } from "../util/analytics";

function OrderConfirmationSection(props) {
  // const router = useRouter();
  const [showConfetti, setShowConfetti] = useState(true);

  useEffect(() => {
    // Stop confetti after 10 seconds
    const timer = setTimeout(() => {
      setShowConfetti(false);
    }, 10000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Meta title="Wavery - Order Confirmed" />
      {showConfetti && <Confetti />}
      <Section
        size={props.size}
        bgColor={props.bgColor}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
        textColor={props.textColor}
        anchor={props.anchor}
      >
        <div className="container flex flex-col space-y-3 text-center lg:flex-row-reverse lg:space-y-0 lg:text-left">
          <div className="lg:w-1/2 lg:self-center">
            <SectionHeader
              title={props.title}
              subtitle={props.subtitle}
              strapline={props.strapline}
            />
            <div className="flex flex-col items-center justify-center pt-10 pb-16 space-y-2 sm:flex-row sm:items-center lg:justify-start sm:space-y-0 sm:space-x-2">
              <Button
                // component={Link}
                // to="/pricing"
                size="xl"
                href="https://github.com/getwavery/whisperscript-2-releases/releases/download/v2.0.0/WhisperScript-2.0.0-arm64-mac.dmg"
                placement={"OrderConfirmation-download"}
                onClick={() => trackEvent("Order Confirmed - Download")}
                endIcon={
                  <ArrowRightIcon className="inline-block w-5 h-5 opacity-70" />
                }
              >
                Start Transcribing Now!
              </Button>
              <Button
                // component={Link}
                // to="/#features"
                size="xl"
                variant="discord"
                className="w-fit"
                placement={"OrderConfirmation-discord"}
                href="https://discord.gg/D45a4yQRp8"
                startIcon={<img src={discordIcon} alt="Discord Icon" />}
              >
                Join the Discord
              </Button>
            </div>
          </div>
          <div className="pb-12 lg:w-1/2 lg:mr-16 lg:flex lg:justify-center lg:items-center md:pb-0">
            <div className="relative h-full">
              {/* VIDEO */}
              <div className={`${layout.sectionImgReverse}`}>
                <div className="z-10 overflow-hidden aspect-[1.68/1] h-full relative rounded-2xl sm:rounded-[25px] border border-[hsla(0,0%,100%,0.08)] shadow-[0_3px_40px_20px_hsla(0,0%,100%,0.04),inset_0_0.5px_0_0_hsla(0,0%,100%,0.3)] [background:radial-gradient(85.77%_49.97%_at_51%_5.12%,hsla(0,100%,100%,0.15)0,rgba(222,226,255,0.1)45.83%,rgba(241,242,255,0.1)100%),rgba(0,0,0,0.44)] [transform:translateZ(0)]">
                  <video
                    src={waveryWorkflowZoom}
                    alt="whisperscript-transcribing"
                    type="video/mp4"
                    className="w-full h-auto"
                    autoPlay
                    muted
                    loop
                    playsInline
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </>
  );
}

export default OrderConfirmationSection;
