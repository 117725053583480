import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAnalytics } from "use-analytics";

export default function AnalyticsTracker() {
  const location = useLocation();
  const analytics = useAnalytics();
  const [windowLocation, setWindowLocation] = useState();

  useEffect(() => {
    setWindowLocation({ ...location, href: window.location.href });
  }, [location]);

  useEffect(() => {
    if (!windowLocation) return;

    console.log("AnalyticsTracker ~ document.title:", document.title);
    console.log("AnalyticsTracker ~ windowLocation:", windowLocation);

    // Send page view on route change with the current location data
    analytics.page({
      url: windowLocation.href,
      path: windowLocation.pathname,
      search: windowLocation.search,
      title: document.title,
    });
  }, [windowLocation, analytics]);

  return null;
}

// Location Object:
// {
//   "ancestorOrigins": {},
//   "href": "http://localhost:8888/",
//   "origin": "http://localhost:8888",
//   "protocol": "http:",
//   "host": "localhost:8888",
//   "hostname": "localhost",
//   "port": "8888",
//   "pathname": "/",
//   "search": "",
//   "hash": ""
// }
