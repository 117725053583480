export default function MacOSScreen({ wallpaperSrc, className, children }) {
  return (
    <div className="relative rounded-[25px] p-2 min-w-[min(1216px,calc(100vw+96px))] h-[94vw] sm:h-[clamp(500px,calc(100vw*0.6+274.436px),740px)] backdrop-blur-[2px] [-webkit-backdrop-filter:blur(2px)] border border-[hsla(0,0%,100%,0.08)] shadow-[0_6px_40px_20px_hsla(0,0%,100%,0.05),inset_0_0.5px_0_0_hsla(0,0%,100%,0.3)] [transform:translateZ(0)] [background:radial-gradient(85.77%_49.97%_at_51%_5.12%,hsla(0,100%,100%,0.15)0,rgba(222,226,255,0.1)45.83%,rgba(241,242,255,0.1)100%),rgba(0,0,0,0.44)]">
      <div className="relative w-full h-full rounded-[19px] shadow-inner shadow-primary/30 overflow-hidden">
        <Wallpaper imgSrc={wallpaperSrc} />
        <div className="relative flex flex-col h-full">
          <MenuBar className="hidden sm:block h-[35px]" />
          <Workspace className={className}>{children}</Workspace>
        </div>
      </div>
    </div>
  );
}

const MenuBar = ({ className = "" } = {}) => {
  return (
    <div className={`bg-gray-800/[.25] backdrop-blur ${className}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        className="w-full h-auto"
      >
        <g className="-translate-y-[6px]">
          <g>
            <path
              fill="#fff"
              d="M33.509 19.749q.165 0 .517.04.359.031.812.175.453.143.923.461a3.4 3.4 0 0 1 .867.892 9 9 0 0 0-.302.215 4 4 0 0 0-.581.557 3.4 3.4 0 0 0-.573.915q-.24.55-.239 1.313 0 .876.303 1.48.31.605.716.98.414.373.732.549.327.167.35.175a12 12 0 0 1-.12.358 9 9 0 0 1-.35.828 7.3 7.3 0 0 1-.604 1.05q-.342.486-.709.932a3.4 3.4 0 0 1-.787.724 1.66 1.66 0 0 1-.956.286q-.404 0-.692-.095a5 5 0 0 1-.55-.223 4 4 0 0 0-.564-.215 2.7 2.7 0 0 0-.772-.095q-.605 0-1.011.159a40 40 0 0 0-.756.326q-.358.16-.844.16-.74 0-1.305-.59a14 14 0 0 1-1.146-1.408 9 9 0 0 1-.828-1.505q-.374-.851-.597-1.806a8.4 8.4 0 0 1-.223-1.91q0-1.53.581-2.571.582-1.042 1.489-1.576a3.67 3.67 0 0 1 1.886-.533q.518 0 .971.167.462.167.86.342.397.167.724.167.31 0 .732-.175.422-.183.931-.366a3.3 3.3 0 0 1 1.114-.183m-.558-1.29a3.2 3.2 0 0 1-1.003.804q-.596.32-1.13.319a1 1 0 0 1-.215-.024l-.023-.112a2 2 0 0 1-.008-.175q0-.605.262-1.178.263-.573.605-.955a3.4 3.4 0 0 1 1.067-.835q.652-.335 1.25-.359.023.135.023.31 0 .614-.23 1.195a3.7 3.7 0 0 1-.598 1.01"
            ></path>
          </g>
          <g>
            <path
              fill="#fff"
              d="M57.303 29v-9.16h5.999v1.58H59.22v2.457h3.726v1.518H59.22V29zm7.598 0v-6.989h1.854V29zm.934-8.061q-.426 0-.737-.299a.99.99 0 0 1-.31-.73q0-.431.31-.73.31-.305.737-.305.431 0 .736.305a.98.98 0 0 1 .305.73 1 1 0 0 1-.305.73 1.01 1.01 0 0 1-.736.299M68.62 29v-6.989h1.854v1.08h.114q.24-.572.749-.902.507-.33 1.257-.33 1.167 0 1.777.685.615.68.616 1.936V29h-1.854v-4.1q0-.744-.31-1.124-.312-.381-.972-.381-.426 0-.736.197a1.3 1.3 0 0 0-.476.54q-.165.348-.165.824V29zm10.658.108q-.87 0-1.51-.432-.636-.437-.985-1.244-.342-.805-.343-1.923v-.013q0-1.123.337-1.93.342-.806.977-1.237t1.524-.432q.475 0 .882.152.412.146.724.42.31.272.482.66h.114v-3.79h1.854V29h-1.854v-1.085h-.114q-.178.375-.482.64a2.1 2.1 0 0 1-.705.413 2.8 2.8 0 0 1-.901.14m.628-1.536q.495 0 .85-.248.357-.248.547-.71.196-.465.197-1.099v-.013q0-.647-.197-1.104a1.55 1.55 0 0 0-.553-.711 1.4 1.4 0 0 0-.844-.254q-.482 0-.844.254a1.56 1.56 0 0 0-.546.71q-.19.459-.19 1.1v.012q0 .64.19 1.104t.546.711q.355.248.844.248m8.417 1.574q-1.06 0-1.828-.438a3 3 0 0 1-1.174-1.257q-.413-.812-.413-1.936v-.006q0-1.11.406-1.93.414-.819 1.162-1.27.756-.45 1.771-.45 1.022 0 1.765.438t1.142 1.225.4 1.847v.578h-5.72v-1.162h4.832l-.87 1.098v-.742q0-.629-.197-1.048a1.4 1.4 0 0 0-.533-.628 1.43 1.43 0 0 0-.787-.216q-.444 0-.794.222-.342.216-.546.641-.195.42-.196 1.029v.749q0 .59.196 1.009.198.42.559.641t.863.222q.407 0 .692-.12.286-.12.457-.28.179-.166.241-.298l.02-.038h1.726l-.013.063q-.082.33-.298.692-.216.356-.597.667a3 3 0 0 1-.94.508q-.558.19-1.326.19M93.072 29v-6.989h1.854v1.086h.114q.171-.571.628-.883.458-.317 1.137-.317.19 0 .368.025.184.025.324.064v1.619a4 4 0 0 0-.426-.077 3 3 0 0 0-.425-.031q-.527 0-.914.196a1.4 1.4 0 0 0-.597.546q-.21.355-.21.85V29z"
            ></path>
          </g>
          <g>
            <path
              fill="#fff"
              d="M117.382 29v-9.16h5.84v1.378h-4.202v2.71h3.84v1.333h-3.84V29zm7.509 0v-6.938h1.574V29zm.794-8.1a.92.92 0 0 1-.667-.273.88.88 0 0 1-.279-.653q0-.395.279-.66a.92.92 0 0 1 .667-.273.9.9 0 0 1 .66.273.88.88 0 0 1 .279.66.88.88 0 0 1-.279.653.9.9 0 0 1-.66.273m2.793 8.1v-9.623h1.58V29zm6.538.14q-1.035 0-1.778-.438a2.9 2.9 0 0 1-1.136-1.245q-.4-.806-.4-1.91v-.006q0-1.092.394-1.905a2.96 2.96 0 0 1 1.13-1.257q.73-.45 1.713-.45.991 0 1.702.438.717.431 1.104 1.212t.387 1.828v.52h-5.636v-1.06h4.862l-.749.99v-.627q0-.693-.21-1.15a1.5 1.5 0 0 0-.584-.685 1.6 1.6 0 0 0-.857-.229q-.488 0-.869.242-.375.234-.597.698-.216.458-.216 1.124v.634q0 .642.216 1.098.216.45.61.699.399.24.945.24.426 0 .73-.12.312-.127.502-.298.19-.177.266-.337l.019-.044h1.498l-.012.057a2.3 2.3 0 0 1-.305.686 2.6 2.6 0 0 1-.59.64 3 3 0 0 1-.902.477 3.9 3.9 0 0 1-1.237.178"
            ></path>
          </g>
          <g>
            <path
              fill="#fff"
              d="M158.169 29v-9.16h5.929v1.378h-4.291V23.7h4.056V25h-4.056v2.622h4.291V29zm10.296.114q-.863 0-1.504-.431-.641-.438-.99-1.244-.35-.806-.349-1.898v-.013q0-1.104.342-1.904.35-.806.984-1.238.642-.438 1.517-.438.477 0 .889.152.412.146.724.419.317.273.495.648h.114v-3.79h1.574V29h-1.574v-1.085h-.114a2.14 2.14 0 0 1-1.213 1.06q-.406.14-.895.14m.495-1.333q.54 0 .927-.266.394-.273.603-.775.216-.507.216-1.2v-.012q0-.699-.216-1.2a1.7 1.7 0 0 0-.603-.774 1.57 1.57 0 0 0-.927-.273q-.533 0-.926.273-.387.266-.597.774-.21.501-.209 1.2v.013q0 .697.209 1.2.21.501.597.774.387.266.926.266M174.273 29v-6.938h1.575V29zm.794-8.1a.92.92 0 0 1-.667-.273.88.88 0 0 1-.279-.653q0-.395.279-.66a.92.92 0 0 1 .667-.273.9.9 0 0 1 .66.273.88.88 0 0 1 .279.66.88.88 0 0 1-.279.653.9.9 0 0 1-.66.273m5.459 8.132q-1.194 0-1.72-.438-.527-.438-.527-1.435v-3.872H177.2v-1.225h1.079v-1.701h1.606v1.701h1.46v1.225h-1.46v3.498q0 .513.216.755.222.235.711.235.171 0 .279-.006.114-.013.254-.026v1.219q-.165.032-.381.05-.21.02-.438.02"
            ></path>
          </g>
          <g>
            <path
              fill="#fff"
              d="m203.643 29-3.168-9.16h1.803l2.266 7.268h.108l2.272-7.268h1.803L205.553 29zm6.519 0v-6.938h1.574V29zm.793-8.1a.92.92 0 0 1-.666-.273.88.88 0 0 1-.28-.653q0-.395.28-.66a.92.92 0 0 1 .666-.273.9.9 0 0 1 .66.273q.28.265.28.66a.88.88 0 0 1-.28.653.9.9 0 0 1-.66.273m5.7 8.24q-1.035 0-1.777-.438a2.9 2.9 0 0 1-1.136-1.245q-.4-.806-.4-1.91v-.006q0-1.092.393-1.905.4-.812 1.13-1.257.73-.45 1.714-.45.99 0 1.701.438.718.431 1.105 1.212t.387 1.828v.52h-5.637v-1.06h4.863l-.749.99v-.627q0-.693-.21-1.15a1.5 1.5 0 0 0-.584-.685 1.6 1.6 0 0 0-.857-.229q-.49 0-.869.242-.375.234-.597.698-.216.458-.216 1.124v.634q0 .642.216 1.098.216.45.609.699.4.24.946.24.426 0 .73-.12.311-.127.501-.298.19-.177.267-.337l.019-.044h1.498l-.013.057a2.3 2.3 0 0 1-.304.686 2.6 2.6 0 0 1-.591.64 3 3 0 0 1-.901.477 3.9 3.9 0 0 1-1.238.178m5.948-.14-1.885-6.938h1.587l1.149 5.218h.114l1.333-5.218h1.504l1.34 5.218h.114l1.149-5.218h1.568L228.69 29h-1.65l-1.352-5.027h-.108L224.234 29z"
            ></path>
          </g>
          <g>
            <path
              fill="#fff"
              d="M254.172 29.229q-.996 0-1.79-.33a3.8 3.8 0 0 1-1.358-.965 4.3 4.3 0 0 1-.857-1.511q-.298-.89-.298-2v-.012q0-1.467.527-2.54.527-1.078 1.485-1.669.964-.59 2.266-.59 1.06 0 1.892.38.837.375 1.371 1.054.533.674.679 1.568l.013.064h-1.625l-.019-.064q-.223-.755-.826-1.168-.603-.419-1.485-.419-.8 0-1.39.413-.584.412-.902 1.168-.317.755-.317 1.79v.013q0 .786.184 1.415.185.63.527 1.073.35.444.838.68.495.234 1.111.234.698 0 1.225-.273.533-.273.831-.762.305-.488.318-1.136v-.19h-2.197v-1.25h3.796v1.142q0 .888-.279 1.606a3.4 3.4 0 0 1-.806 1.225 3.5 3.5 0 0 1-1.263.78 4.7 4.7 0 0 1-1.651.274m8.741-.09q-1.034 0-1.784-.431a2.94 2.94 0 0 1-1.155-1.244q-.4-.806-.4-1.93v-.012q0-1.112.406-1.917a2.9 2.9 0 0 1 1.149-1.245q.75-.43 1.784-.431 1.035 0 1.777.431.75.431 1.156 1.238t.406 1.924v.012q0 1.124-.406 1.93a2.9 2.9 0 0 1-1.149 1.244q-.743.432-1.784.432m0-1.282q.546 0 .927-.273a1.73 1.73 0 0 0 .59-.793q.203-.52.203-1.25v-.013q0-.737-.203-1.25-.203-.521-.59-.794-.381-.28-.927-.28t-.933.28q-.387.273-.59.793-.203.515-.203 1.25v.014q0 .73.203 1.25t.584.793q.387.273.939.273"
            ></path>
          </g>
          <g>
            <path
              fill="#fff"
              d="m288.51 29-2.456-9.16h1.701l1.574 6.875h.089l1.809-6.875h1.454l1.828 6.875h.089l1.568-6.875h1.688l-2.45 9.16h-1.536l-1.867-6.557h-.095L290.053 29zm10.893 0v-6.938h1.574V29zm.793-8.1a.92.92 0 0 1-.666-.273.88.88 0 0 1-.28-.653q0-.395.28-.66a.92.92 0 0 1 .666-.273.9.9 0 0 1 .66.273q.28.265.28.66a.88.88 0 0 1-.28.653.9.9 0 0 1-.66.273m2.73 8.1v-6.938h1.58v1.066h.108q.248-.564.749-.882.502-.317 1.251-.317 1.161 0 1.777.673.616.672.616 1.91V29h-1.581v-4.12q0-.812-.343-1.218-.336-.407-1.053-.407-.47 0-.813.21t-.527.59a2.1 2.1 0 0 0-.184.901V29zm10.448.114q-.863 0-1.504-.431-.642-.438-.991-1.244-.348-.806-.349-1.898v-.013q0-1.104.343-1.904.35-.806.984-1.238.64-.438 1.517-.438.476 0 .889.152.412.146.723.419.318.273.495.648h.115v-3.79h1.574V29h-1.574v-1.085h-.115q-.19.374-.501.647-.304.266-.711.413-.406.14-.895.14m.495-1.333q.54 0 .927-.266.393-.273.603-.775.216-.507.216-1.2v-.012q0-.699-.216-1.2a1.73 1.73 0 0 0-.603-.774 1.57 1.57 0 0 0-.927-.273q-.533 0-.927.273-.387.266-.596.774-.21.501-.21 1.2v.013q0 .697.21 1.2.209.501.596.774.388.266.927.266m8.29 1.359q-1.034 0-1.784-.432a2.94 2.94 0 0 1-1.155-1.244q-.4-.806-.4-1.93v-.012q0-1.112.407-1.917.405-.813 1.148-1.245.75-.43 1.784-.431 1.034 0 1.778.431.749.431 1.155 1.238t.406 1.924v.012q0 1.124-.406 1.93a2.9 2.9 0 0 1-1.149 1.244q-.743.432-1.784.432m0-1.283q.546 0 .927-.273a1.73 1.73 0 0 0 .59-.793q.204-.52.203-1.25v-.013q0-.737-.203-1.25-.203-.521-.59-.794-.381-.28-.927-.28t-.933.28q-.387.273-.59.793-.203.515-.203 1.25v.014q0 .73.203 1.25t.584.793q.387.273.939.273M328.132 29l-1.885-6.938h1.587l1.149 5.218h.114l1.333-5.218h1.505l1.339 5.218h.114l1.149-5.218h1.568L334.22 29h-1.651l-1.352-5.027h-.108L329.764 29z"
            ></path>
          </g>
          <g>
            <path
              fill="#fff"
              d="M356.365 29v-9.16h1.638v3.809h4.456V19.84h1.631V29h-1.631v-3.974h-4.456V29zm12.797.14q-1.035 0-1.777-.438a2.9 2.9 0 0 1-1.136-1.245q-.4-.806-.4-1.91v-.006q0-1.092.393-1.905.4-.812 1.13-1.257.73-.45 1.714-.45.99 0 1.701.438.717.431 1.105 1.212.387.78.387 1.828v.52h-5.637v-1.06h4.862l-.749.99v-.627q0-.693-.209-1.15a1.5 1.5 0 0 0-.584-.685 1.6 1.6 0 0 0-.857-.229q-.489 0-.87.242-.374.234-.596.698-.216.458-.216 1.124v.634q0 .642.216 1.098.216.45.609.699.4.24.946.24.425 0 .73-.12a1.7 1.7 0 0 0 .501-.298q.19-.177.267-.337l.019-.044h1.498l-.013.057a2.3 2.3 0 0 1-.304.686 2.6 2.6 0 0 1-.591.64 3 3 0 0 1-.901.477 3.9 3.9 0 0 1-1.238.178m4.761-.14v-9.623h1.58V29zm3.567 2.304v-9.242h1.581v1.085h.108a2.1 2.1 0 0 1 .495-.64q.31-.267.717-.413t.895-.146q.87 0 1.505.438.64.438.99 1.244.355.8.355 1.898v.013q0 1.104-.349 1.904-.35.8-.99 1.238-.635.43-1.511.431-.475 0-.889-.146a2.3 2.3 0 0 1-.723-.425 2 2 0 0 1-.495-.648h-.108v3.41zm3.301-3.523q.54 0 .927-.266.387-.273.596-.775.21-.507.21-1.2v-.012q0-.705-.21-1.206a1.7 1.7 0 0 0-.596-.768 1.57 1.57 0 0 0-.927-.273q-.54 0-.933.273a1.74 1.74 0 0 0-.603.768q-.21.501-.21 1.2v.012q0 .699.21 1.206.216.502.609.775.394.266.927.266"
            ></path>
          </g>
        </g>
      </svg>
    </div>
  );
};

const Workspace = ({ className = "", children } = {}) => {
  return <div className={`flex-1 ${className}`}>{children}</div>;
};

const Wallpaper = ({ imgSrc }) => {
  return imgSrc ? (
    <img src={imgSrc} alt="wallpaper" className="absolute w-full h-full" />
  ) : (
    <div className="absolute inset-0 bg-gradient-to-br from-primary/80 via-cyan-400/30 to-primary/60" />
  );
};
